.main {
  position: relative;
  z-index: 1;
}

.content {
  flex: 1; /* This ensures the body takes up the remaining space */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center; /* This centers the text of each element */
  width: 100%;
  padding-bottom: 50px; /* Adding padding at the bottom of the content */
}

.content h1,
.content p {
  color: black; /* Makes the text color of content white */
  margin-top: 40px; /* Adding margin at the top of h1 and p tags */
}

.stress {
  font-family: "Anybody", sans-serif;
  font-size: 2em; /* Increase the text size. Adjust as needed. */
  font-variation-settings: "wght" 700, "wdth" 100; /* Adjust the values (100 to 900) for different weight and width */
  line-height: 1.2; /* Adjust the line-height as per requirement */
  width: 100%; /* Full width */
}

.home1 {
  font-family: "Anybody", sans-serif;
  font-size: 3em; /* Increase the text size. Adjust as needed. */
  font-variation-settings: "wght" 700, "wdth" 100; /* Adjust the values (100 to 900) for different weight and width */
  line-height: 1.2; /* Adjust the line-height as per requirement */
  width: 100%; /* Full width */
}

.p9 {
  font-size: 1.2em; /* Increase the text size. Adjust as needed. */
  font-variation-settings: "wght" 600, "wdth" 100; /* Adjust the values (100 to 900) for different weight and width */
  line-height: 1.2; /* Adjust the line-height as per requirement */
  width: 100%; /* Full width */
}

/* Gradient hover effect for .home1 and .p9 */
.home1,
.p9,
.stress {
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-duration: 400ms;
  transition-property: background-color;
  background: linear-gradient(135deg, #1afbf0e6, #d900ffc8);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.home1:focus,
.home1:hover,
.p9:focus,
.p9:hover,
.stress:focus,
.stress:hover {
  background-position: 100% 0;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  border-inline-color: transparent;
}

/* Animation styles for :after pseudo-element */
.home1:after,
.p9:after,
.stress:after {
  content: "";
  pointer-events: none;
  bottom: -2px;
  left: 50%;
  position: absolute;
  width: 0%;
  height: 2px;
  background-color: RGB(114, 137, 217);
  transition: width 400ms cubic-bezier(0.25, 0.8, 0.25, 1),
    left 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
}

/* Video Content Style */
.video10 {
  margin-top: 70px;
  width: 100%;
  position: relative;
  object-fit: cover; /* This will cover the entire wrapper area */
  overflow: hidden; /* This will ensure that the video is contained within the wrapper */
}

/* Services we provide styles */
.service h1 {
  color: black; /* Makes the text color of content white */
  margin-top: 70px;
}

.heading1 {
  text-align: center;
  font-size: 3em;
  font-variation-settings: "wght" 700, "wdth" 100;
  line-height: 1.2;
  width: 100%;
  color: black; /* Set the initial text color to black */
}

.heading1:hover {
  background: linear-gradient(135deg, #1afbf0e6, #d900ffc8);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; /* Make the text transparent to show the gradient */
  border-inline-color: transparent; /* For browsers other than Safari */
  transition: text-fill-color 400ms cubic-bezier(0.25, 0.8, 0.25, 1); /* Add transition for color change */
}

.heading1:after {
  content: "";
  pointer-events: none;
  bottom: -2px;
  left: 50%;
  position: absolute;
  width: 0%;
  height: 2px;
  background-color: RGB(114, 137, 217);
  transition: width 400ms cubic-bezier(0.25, 0.8, 0.25, 1),
    left 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
}

.grid-container2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 60px;
  padding: 20px;
  margin-bottom: 50px;
  height: auto;
  transition: box-shadow 0.3s ease, transform 0.2s ease;
  /* Add positioning properties */
  position: relative;
  top: 50px; /* Adjust the value to move the container up or down */
  left: 50px; /* Adjust the value to move the container left or right */
  @media screen and (max-width: 960px) {
    left: 0;
  }
  @media screen and (max-width: 560px) {
    grid-template-columns: repeat(1, 1fr);
    .grid-item2 {
      width: 100%;
    }
  }
}

.grid-item2 {
  width: 80%;
  height: fit-content;
  padding: 12px;
  font-size: 1.1em; /* Increase the text size. Adjust as needed. */
  text-align: center; /* Align the text to the center */
  justify-content: center; /* Justify the text (applies to multi-line text) */
  line-height: 1.2; /* Adjust the line-height as per requirement */
  background: #64dce47d;
  /* border-radius: 23px; */
  box-shadow: #64dce47d 0px 8px 10px;
  transition: box-shadow 0.3s ease, transform 0.2s ease;
  /* box-shadow: rgba(89, 87, 87, 0.17) 0px -23px 25px 0px inset,
   rgba(95, 94, 94, 0.15) 0px -36px 30px 0px inset, rgba(101, 98, 98, 0.1) 0px -79px 40px 0px inset,
    rgba(92, 90, 90, 0.06) 0px 2px 1px, rgba(100, 98, 98, 0.09) 0px 4px 2px, rgba(99, 97, 97, 0.09) 0px 8px 4px,
     rgba(100, 98, 98, 0.09) 0px 16px 8px, rgba(110, 107, 107, 0.09) 0px 32px 16px; */
  transition-property: background-color;
  background: linear-gradient(135deg, #1afbf0e6, #d900ffc8);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.grid-item2:hover {
  box-shadow: #d900ff5d 0px 12px 20px;
  transform: translateY(-5%);
}

.h3 {
  text-align: justify; /* Center align the text */
  line-height: 1.2; /* Adjust the line-height as per requirement */
  width: 100%; /* Full width */
  justify-content: center; /* Justify the text (applies to multi-line text) */
}
