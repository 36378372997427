.main-content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
    background-color: #f8f8f8;
  }
  
  .content-section {
    flex: 1; /* Take up half the space */
    max-width: 600px; /* Adjust as needed */
    padding-right: 40px;
  }
  
  .content-section h1 {
    font-size: 2.5em;
    color: #333;
    margin-bottom: 20px;
  }
  
  .content-section p {
    font-size: 1em;
    color: #666;
  }
  
  .card-image {
    width: 100%; /* Make the image fill the card */
    height: 100%; /* Make the image fill the card */
    width: 500px;
    height: 350px;
    backdrop-filter: blur(7px);
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50px;
    box-shadow: 20px 20px 8px 0 rgba(157, 217, 255, 0.5);
    transition: all 0.3s;
    overflow: hidden; /* This will ensure that the image does not bleed outside the border-radius */
  }
  
  /* Styles for BenefitsBar */
  .benefits-bar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
    background-color: #283742fe;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .benefit {
    display: flex;
    align-items: center;
    font-size: 0.9em;
    color: #e5e5e5;
  }
  
  .benefit span {
    margin-left: 10px;
  }

  .vision-content {
    display: flex;
    flex-direction: row; /* Ensures that the image comes first (left side) */
    justify-content: center;
    align-items: center;
    padding: 50px;
    background-color: #f8f8f8;
  }
  
  .vision-section {
    flex: 1; /* Take up half the space */
    max-width: 600px; /* Adjust as needed */
    padding-right: 40px; /* Ensure there is some space between the text and the image */
  }
  
  .vision-section h1 {
    font-size: 2.5em;
    color: #333;
    margin-bottom: 20px;
  }
  
  .vision-section p {
    font-size: 1em;
    color: #666;
  }
  
  .image-section { /* Changed from .card-image2 to .image-section to match React component */
    flex: 1; /* Take up the other half of the space */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .card-image2 {
    max-width: 100%; /* Make the image responsive within the container */
    max-height: 100%; /* Adjust if necessary */
    width: 500px;
    height: 450px;
    border-radius: 20px; /* Adjust if you want rounded corners */
    backdrop-filter: blur(7px);
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: -20px 20px 8px 0 rgba(157, 217, 255, 0.5);
    transition: all 0.3s;
    overflow: hidden;
  }

  /* Learn More styles */
.secret h2 {
  color: black; /* Makes the text color of content white */
}

.heading2 {
  text-align: center;
  font-size: 2.8em;
  font-variation-settings: 'wght' 700, 'wdth' 100;
  line-height: 1.2;
  width: 100%;
  margin-top: 80px;
  color: black; /* Set the initial text color to black */
}

.heading2:hover {
  background: linear-gradient(135deg, #1afbf0e6, #d900ffc8);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; /* Make the text transparent to show the gradient */
  border-inline-color: transparent; /* For browsers other than Safari */
  transition: text-fill-color 400ms cubic-bezier(0.25, 0.8, 0.25, 1); /* Add transition for color change */
}

.heading2:after {
  content: "";
  pointer-events: none;
  bottom: -2px;
  left: 50%;
  position: absolute;
  width: 0%;
  height: 2px;
  background-color: RGB(114, 137, 217);
  transition: width 400ms cubic-bezier(0.25, 0.8, 0.25, 1), left 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
}

    /* Add a container for the FAQ elements */
    .faq-container {
      display: flex; /* Use Flexbox layout */
      justify-content: space-between; /* Distribute elements with equal spacing in between */
      margin-top: 2em;
      margin-left: 5em;
      margin-right: 5em;
      margin-bottom: 5em;
      gap: 40px;  
    }

    /* Common styles for all the faq elements */
    .faq1, .faq2, .faq3 {
      cursor: pointer;
      width: 350px;
      height: 370px;
      background: rgb(255, 255, 255);
      border-radius: 5px;
      border: 1px solid rgba(0, 0, 255, .2);
      transition: all .2s;
      box-shadow: 12px 12px 2px 1px rgba(0, 0, 255, .2);
      display: flex; /* Use flexbox layout to align content vertically */
      flex-direction: column; /* Stack the elements vertically inside the card */
      align-items: left; /* Center the content horizontally */
      padding: 20px; /* Add some padding to create space for text content */
    }

    .faq1:hover, .faq2:hover, .faq3:hover {
      box-shadow: -12px 12px 2px -1px rgba(0, 0, 255, .2);
    }
    
    /* Style the heading and paragraph inside the faq cards */
    .faq1 h2, .faq2 h2, .faq3 h2 {
      font-size: 1.3em;
      margin-bottom: -10px;
      align-items: left; /* Center the content horizontally */
    }
    
    .faq1 p, .faq2 p, .faq3 p {
      font-size: 1.1em;
      text-align: left;
      margin-bottom: -10px;
    }
    
    .faq1 img, .faq2 img, .faq3 img {
      display: block; /* This makes the image a block-level element */
      max-width: 100%; /* This makes sure the image is not wider than its container */
      height: auto; /* This keeps the image aspect ratio intact */
      margin-top: 20px; /* This adds space between the text and the image */
    }

    .mtt-container {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      justify-content: space-evenly;
    }

    .mtt-upper {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
    }

    /* pragati and ingrid */
    .mtt-middle {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      margin-bottom: 20px;
    }

    .mtt-middle > .mtt-card {
      margin-left: 65px;
      margin-right: 65px;
    }

    /* everyone else */
    .mtt-lower {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
    }

    .mtt-lower > .mtt-card {
      margin-left: 25px;
      margin-right: 25px;
    }
    
    .mtt-card {
      color: #1b1b1b;
      width: calc(75%/5);
      height: 254px;
      position: relative;
      outline: 6px solid #f5f5f5;
      border-radius: 8px;
      line-height: 150%;
      padding: 16px;
      background: #ff930f;
      background-blend-mode: multiply;
      background: linear-gradient(135deg, #1afbf055, #d900ff52);
      transition: background-color 1s ease-in-out;
      overflow: hidden;
      margin-bottom: 20px;
      float: left;
      margin-left: 15px;
      margin-right: 15px
    }
    
    .mtt-card-front {
      bottom: 16px;
      left: 0;
      position: absolute;
      width: 100%;
      text-align: center;
      transition: transform 1s cubic-bezier(0.785, 0.135, 0.150, 0.860);
    }
    
    .mtt-card-back {
      transform: translateX(120%);
      transition: transform 1s cubic-bezier(0.785, 0.135, 0.150, 0.860);
    }
    
    /*Text*/
    .name {
      font-size: 1.3rem;
      font-weight: bold;
    }
    /*Text divider*/
    .name::after {
      content: "";
      display: block;
      width: 50%;
      border-radius: 50%;
      height: 2px;
      margin: 2px auto;
      background-color: #1b1b1b;
    }
    
    /*Hover*/
    .mtt-card:hover {
      background-color: #1b1b1b25;
    }
    
    .mtt-card:hover .mtt-card-front {
      transform: translateX(-100%);
    }
    
    .mtt-card:hover .mtt-card-back {
      transform: translateX(0);
    }