.App {
  min-height: 100vh; /* This ensures that the container takes up the full height of the viewport */
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

body {
  font-family: 'Anybody', sans-serif;
  position: relative;
  z-index: 0;
  min-height: 100vh; 
  width: 100%;
}

@font-face {
  font-family: 'Anybody';
  src: url('assets/fonts/Anybody-1.111/fonts/variable/Anybody[ital,wdth,wght].ttf') format('truetype');
}

/* Main content area (posters) */
.main-content {
  overflow-y: auto; /* allow scrolling if content is longer than available space */
  /* min-height: 100vh; This ensures that the container takes up the full height of the viewport */
  flex: 1; /* This lets the main content grow */
} 



