.result-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  width: 100%;
}

.table-container {
  overflow-x: auto; /* Allows table to scroll on small screens */
  width: 100%;
  position: relative;
  top: 9px;
  height: auto; /* Adjusted to 'auto' to accommodate content height */
  background: linear-gradient(135deg, #1afbf07d, #d900ff5d);
  font-family: 'Anybody', sans-serif; /* Replace with actual font if 'Anybody' is a placeholder */
  font-weight: 200;
  overflow-x: auto; /* Allows table to scroll horizontally if needed */
  overflow-y: hidden; /* Prevents vertical overflow */
  box-shadow: 0 0 20px rgba(0,0,0,0.1);
}

table {
  width: 100%;
  border-collapse: collapse; /* Collapses border to remove space between cells */
}

th, td {
  background-color: rgba(255,255,255,0.2); /* Semi-transparent cells to allow gradient to show through */
  padding: 8px; /* Adjust padding to your preference */
  color: #000000;
  text-align: center;
  border: 1px solid #ddd; /* Adds a light border to the bottom of each cell */
}

td {
  font-weight: 300;
}

thead th {
  background-color: transparent; /* Make the header cells transparent to show the gradient */
  color: rgb(0, 0, 0); /* Adjust text color for better visibility on gradient */
}

tbody tr:nth-child(odd) {
  background-color: rgba(255,255,255,0.2); /* Maintain transparency for odd rows */
}

tbody tr:nth-child(even) {
  background-color: rgba(255,255,255,0.2); /* Maintain transparency for even rows */
}

tbody tr:hover {
  background-color: rgba(255,255,255,0.3);
}

.pagination {
  padding: 25px 0; /* Padding above and below the pagination */
  margin-top: auto; /* This will push the footer to the bottom */
  width: 100%;
  text-align: center;
}

.pagination button {
  margin: 0 5px; /* Add some margin between buttons */
  cursor: pointer;
  text-decoration: none; /* Remove underline from links */
  color: #000000; /* Set text color for buttons */
  background-color: #f0f0f0; /* Light background for buttons */
  border-radius: 5px; /* Slight roundness on buttons for aesthetic */
  border: none; /* Remove default button border */
  outline: none; /* Remove focus outline */
  display: flex;
  margin-top: auto; /* This will push the footer to the bottom */
}

.pagination button:hover {
  background-color: #ddd; /* Darken button background on hover */
}

.pagination .current {
  font-weight: bold;
  color: #000000;
  pointer-events: none; /* Disable interactions to indicate current page */
}
  